import React from 'react';
import { Helmet } from 'react-helmet';

import LoginLayout from '../../layout/LoginLayout';
import { FUserTypeEnum } from '../../reducers/userReducer';
import debug from '../../utils/debug';
import LoginView from '../../views/login/Index';
const LoginPage = (): React.ReactElement => {
  debug('Login page is loaded');
  return (
    <LoginLayout>
      <Helmet title="管理员登录"></Helmet>
      <LoginView userType={FUserTypeEnum.ADMIN} />
    </LoginLayout>
  );
};

export default LoginPage;
